import { makeAutoObservable } from "mobx";

export default class LoadingStore {

    isLoading: boolean = false;

    error: string = "";

    constructor() {
        makeAutoObservable(this)
    }

    setIsLoading = (temp: boolean) => {
        this.isLoading = temp;

        if (temp === true) {
            this.setError("")
        }
    }

    setError = (temp: string) => {
        this.error = temp;
    }
}
