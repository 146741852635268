import axios from "axios";
import { makeAutoObservable } from "mobx";
import jwt_decode from "jwt-decode";



export default class AuthStore {

    jwtToken: string | null = localStorage.getItem("jwtToken");

    decodedToken: {
        unique_name: string;
        nameid: string;
        email: string;
        nbf: number;
        exp: number;
        iat: number;
    } = { unique_name: "", nameid: "", email: "", exp: 0, iat: 0, nbf: 0 };

    logginSetting: {
        isShow: boolean;
        isLogin: boolean;
        isGetStarted: boolean;
    } = { isShow: false, isLogin: false, isGetStarted: false };

    isAllowLoginClose: boolean = true;

    isShowLogOutScreen: boolean = false;

    isCurrentUserAdmin: boolean = false;

    currentAdminLevel: number = 0;

    isAdminAccessClientAndShowWarning: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    openLogin = () => {
        this.logginSetting = {
            isShow: true,
            isLogin: true,
            isGetStarted: false
        }
    }

    isDisableClientSideAction = (): boolean => {
        let result = false;

        if (this.isAdminAccessClientAndShowWarning === true) {
            return true;
        }

        return result;
    }

    getRequestHeader = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` },
        };

        return config;
    }

    openGetStarted = () => {
        this.logginSetting = {
            isShow: true,
            isLogin: false,
            isGetStarted: true
        }
    }

    closeLogin = () => {
        this.logginSetting = {
            isShow: false,
            isLogin: false,
            isGetStarted: false
        }
    }

    openAuthLogout = () => {
        this.isShowLogOutScreen = true;
    }

    closeAuthLogout = () => {
        this.isShowLogOutScreen = false;
    }

    setJwtToken = (tempToken: string) => {
        this.jwtToken = tempToken;
        localStorage.setItem("jwtToken", tempToken)
    }

    clearJwtToken = () => {
        localStorage.setItem("jwtToken", "")
    }

    getCurrentIrduserId = (): string | null => {
        return localStorage.getItem("currentIrdUserId");
    }
    setCurrentIrduserId = (currentIrdUserId: string) => {
        localStorage.setItem("currentIrdUserId", currentIrdUserId)
    }

    setEmailUsed = (tempEmail: string) => {
        localStorage.setItem("emailUsed", tempEmail)
    }

    isTokenValid = async (isAdmin: boolean): Promise<boolean> => {
        const config = {
            headers: { Authorization: `Bearer ${this.jwtToken}` },
        };

        const bodyParameters = {
            IsAdmin: isAdmin,
        };

        let tempResult = false;

        await axios
            .post("/BeforeLogin/IsTokenValid", bodyParameters, config)
            .then((response) => {
                if (response.data === true) {
                    tempResult = true;
                } else {
                    //not admin, then show login

                    this.openAuthLogout();
                    console.log("IsTokenValid - 122")
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-lone-blocks
                {
                    //not admin, then show login
                    console.log(error);

                    this.openAuthLogout();

                    console.log("IsTokenValid - 133")
                }
            });

        return tempResult;
    }

    checkIsThisAdmin = async () => {
        const config = {
            headers: { Authorization: `Bearer ${this.jwtToken}` },
        };
        const bodyParameters = {
        };

        console.log(this.jwtToken)
        await axios
            .post("/BeforeLogin/IsAdmin", bodyParameters, config)
            .then((response) => {
                console.log(response.data);

                if (response.data === true) {
                    this.isCurrentUserAdmin = true;

                    axios
                        .post("/Admin/GetCurrentAdminLevel", bodyParameters, config)
                        .then((response) => {
                            console.log(response.data);

                            this.currentAdminLevel = response.data;
                        })
                        .catch((error) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                                //not admin, then show login
                                console.log(error);

                                this.isCurrentUserAdmin = false;
                            }
                        });



                } else {
                    //not admin, then show login
                    this.isCurrentUserAdmin = false;
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-lone-blocks
                {
                    //not admin, then show login
                    console.log(error);

                    this.isCurrentUserAdmin = false;
                }
            });

    }

    setIsAdminAccessClient = (state: boolean) => {
        this.isAdminAccessClientAndShowWarning = state;
    }

    setDecodedToken = (thisToken: string) => {
        this.decodedToken = jwt_decode(thisToken);
    }

    checkLoggedUser = async (location: any) => {
        await this.checkIsThisAdmin();

        this.setIsAdminAccessClient(false)

        if (this.jwtToken !== null && this.jwtToken !== "") {
            this.setDecodedToken(this.jwtToken);
        }

        if (location.pathname.toLowerCase().includes('dashboard') === true && location.pathname.toLowerCase().includes('admin') === false) {

            if (this.jwtToken === null || this.jwtToken === "") {
                //No user, then show login
                this.openAuthLogout();

                console.log("checkLoggedUser - 161")

            } else {
                await this.isTokenValid(false);
            }

            if (this.isCurrentUserAdmin !== false) {

                //check if admin
                //use A try to access B, then show login

                var checkIfAdmin = await this.isTokenValid(true);

                if (checkIfAdmin === true) {
                    this.isAdminAccessClientAndShowWarning = true;
                } else {
                    //
                    this.openAuthLogout();

                    console.log("checkLoggedUser - 180")
                }

            }
        }

        if (location.pathname.toLowerCase().includes('admin')) {


            if (this.jwtToken === null || this.jwtToken === "") {
                //No user, then show login
                this.openAuthLogout();

                console.log("checkLoggedUser - 193")
            } else {
                await this.isTokenValid(true)
            }
        }
    }
}
