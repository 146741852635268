import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../mobxStores/store";
import { TermsEachPoint, TermsHeading } from "./TermsCondition";

interface IPrivacy {
  isShow: boolean;
  closeHandler: Function;
  isModal: boolean;
}

const Privacy = ({ isShow, closeHandler, isModal }: IPrivacy) => {
  const { landingPageStore } = useStore();

  const Content = (): JSX.Element => {
    return (
      <>
        {" "}
        <div style={{ ...TermsHeading }}>
          {landingPageStore.getSoftwareName()}'s Privacy Policy
        </div>{" "}
        {/* Privacy */}
        <div style={{ ...TermsHeading }}>Privacy</div>
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()} has created this privacy policy
          because we value our users and recognise their right to keep personal
          information private.
          {landingPageStore.getSoftwareName()} is dedicated to keeping personal
          information secure. This includes physical security, computer and
          network security, communications security and personnel security.
          Access control is maintained to ensure your privacy and adhere to the
          requirements of New Zealand’s privacy laws. This statement applies
          solely to information collected by this Website / Software.
        </div>
        {/* Confidentiality */}
        <div style={{ ...TermsHeading }}>Confidentiality</div>
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()} recognises that information
          related to you is highly personal and sensitive. Access to your
          personal information is provided by use of your username(your email)
          and one-time password. You are responsible for ensuring the privacy
          and security of your username. You may choose to send or store
          personal information at
          {landingPageStore.getSoftwareName()}. This could include contact, next
          of kin, and payroll related information. If you contact us with a
          request for assistance in using site features or report an error,
          authorised {landingPageStore.getSoftwareName()}
          employees may access your user registration details solely in order to
          diagnose your problem and identify solutions.
        </div>
        {/* Disclosure */}
        <div style={{ ...TermsHeading }}>Disclosure</div>
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()} does not sell, rent or otherwise
          make available any personal information to third parties except where
          required to or permitted to by law, where you have authorised us to do
          so, or where disclosure is connected to the purposes for which the
          information was collected. Your information will be retained only for
          as long as necessary to fulfil the purposes for which it was
          collected.
        </div>
        {/* Links   */}
        <div style={{ ...TermsHeading }}>Links </div>
        <div style={{ ...TermsEachPoint }}>
          This Website / Software may contain links to other sites. Please be
          aware that we are not responsible for the practices of other sites.
          Please review the privacy statements of other sites that may collect
          personally identifiable information.
        </div>{" "}
        {/* Copyright   */}
        <div style={{ ...TermsHeading }}>Copyright </div>
        <div style={{ ...TermsEachPoint }}>
          No part of this Website / Software may be reproduced, translated or
          transmitted in any form or by any means, electronic or otherwise, or
          stored in a retrieval system of any nature without the express
          permission of {landingPageStore.getSoftwareName()}.
        </div>
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()} logo and the program are
          registered trademarks of Flash Payroll. All other trademarks are the
          property of their respective owners and used with permission.
        </div>
        <div style={{ ...TermsEachPoint }}>
          {landingPageStore.getSoftwareName()}’s Privacy Policy is subject to
          change without notice.
        </div>
      </>
    );
  };

  return isModal ? (
    <Modal
      destroyOnClose
      centered={true}
      bodyStyle={{
        height: "80vh",
        overflowY: "scroll",
      }}
      style={{ backgroundColor: "#FFFFFF", paddingBottom: 0 }}
      visible={isShow}
      footer={null}
      onCancel={() => {
        closeHandler();
      }}
    >
      {Content()}
    </Modal>
  ) : (
    Content()
  );
};

export default observer(Privacy);
