import moment from "moment";
import { useStore } from "../../../mobxStores/store";


export const GetPayrunPeriod = (): string | undefined => {

    const { payrunStore } = useStore();

    let result = undefined;

    if (
        payrunStore.currentPayrun !== undefined &&
        payrunStore.currentPayrun.payPeriodStartDate !== "" &&
        payrunStore.currentPayrun.payPeriodEndDate !== "" &&
        payrunStore.currentPayrun.payPeriodStartDate !== undefined &&
        payrunStore.currentPayrun.payPeriodEndDate !== undefined
    ) {
        result =
            moment(
                payrunStore.currentPayrun.payPeriodStartDate,
                "YYYY-MM-DD"
            ).format("DD MMM") +
            " to " +
            moment(payrunStore.currentPayrun.payPeriodEndDate, "YYYY-MM-DD").format(
                "DD MMM YYYY"
            );
    }

    return result;
};


export const CheckEmployeePayTypeCategory = (paytypeId: number, checkCategory: { min: number, max: number }): boolean => {
    let result: boolean = false;

    if (paytypeId >= checkCategory.min && paytypeId <= checkCategory.max) {
        result = true;
    }

    return result;
}