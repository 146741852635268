export const AdminDashboardListEnum = {
  WaitClearance: "Waiting clearance",
  PayEmployee: "Pay employees",
  FiletoIRD: "File to IRD",
  RefundList: "Refund list",
  eeBankAccError: "Ee bank acc. error",
  FileFailed: "File failed",
  PayPAYEtoHolding: "Pay To PAYE Holding",
  PayServiceFee: "Pay service fee",
  PayIRDfromHolding_DueNow: "Due now",
  PayIRDfromHolding_Due20thThisMonth: "Due 20th this month",
  PayIRDfromHolding_Total: "Pay IRD from holding total",
};
