import { useMediaQuery } from "react-responsive";

export const IsCellPhone = (): boolean => {
  const result = useMediaQuery({
    query: "(max-device-width: 600px)",
  });

  return result;
};

export const IsLargeDevice = (): boolean => {
  const result = useMediaQuery({
    minWidth: 1920,
  });

  return result;
};
