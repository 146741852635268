import axios from "axios";
import { makeAutoObservable } from "mobx";
import { DashboardMenuEnum } from "../afterLogin/Dashboard/DashboardMenuEnum";
import { store } from "./store";

export default class DashboardStore {

    dashboardPosition: string = DashboardMenuEnum.Payroll;

    constructor() {
        makeAutoObservable(this)
    }

    setCurrentDashboardPosition = (temp: string) => {
        this.dashboardPosition = temp;
    }

    removeDashboardAlert = () => {

        let isRemoveAlert = false;
        let removeLeaveAlert = false;
        let removeReimbursementAlert = false;

        if (
            store.dashboardStore.dashboardPosition === DashboardMenuEnum.UpcomingLeave &&
            store.irdUserStore.currentIrdUser?.isShowUpcomingLeaveAlert === true
        ) {
            isRemoveAlert = true;
            removeLeaveAlert = true;
        }
        // else if (
        //     store.dashboardStore.dashboardPosition === DashboardMenuEnum.Reimbursement &&
        //     store.irdUserStore.currentIrdUser?.isShowUpcomingReimbursementDeductionAlert ===
        //     true
        // ) {
        //     isRemoveAlert = true;
        //     removeReimbursementAlert = true;
        // }

        if (isRemoveAlert === true) {
            setTimeout(() => {
                const bodyParameters = {
                    AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
                    RemoveLeaveAlert: removeLeaveAlert,
                    RemoveReimbursementAlert: removeReimbursementAlert
                }
                axios
                    .post(
                        "/Dashboard/RemoveDashBoardAlert",
                        bodyParameters,
                        store.authStore.getRequestHeader()
                    )
                    .then((response) => {
                        store.irdUserStore.getCurrentIrdUserFromDB("dashboardStore");
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                            console.log(error);
                        }
                    });
            }, 2000);

        }

    }
}
