import { createContext, useContext } from "react";
import AdminMemuStore from "./AdminStore/adminMenuStore";
import AdminPayNowStore from "./AdminStore/adminPayNowStore";
import AuthStore from "./authStore";
import ClientMemuStore from "./clientMenuStore";
import DashboardStore from "./dashboardStore";
import EmployeeLeaveStore from "./employeeLeaveStore";
import EmployeeReimburseDeductionStore from "./employeeReimburseDeductionStore";
import EmployeeStore from "./employeeStore";
import IrdUserStore from "./irdUserStore";
import LandingPageStore from "./landingPageStore";
import LoadingStore from "./loadingStore";
import PayRunStore from "./payrunStore";

interface Store {
  landingPageStore: LandingPageStore;

  authStore: AuthStore;
  irdUserStore: IrdUserStore;
  employeeStore: EmployeeStore;
  employeeLeaveStore: EmployeeLeaveStore;
  employeeReimburseDeductionStore: EmployeeReimburseDeductionStore;
  clientMemuStore: ClientMemuStore;
  dashboardStore: DashboardStore;
  payrunStore: PayRunStore;

  loadingStore: LoadingStore;

  adminMenuStore: AdminMemuStore;

  adminPayNowStore: AdminPayNowStore;
}

export const store: Store = {
  landingPageStore: new LandingPageStore(),

  //client store
  authStore: new AuthStore(),
  irdUserStore: new IrdUserStore(),
  clientMemuStore: new ClientMemuStore(),
  employeeStore: new EmployeeStore(),
  employeeLeaveStore: new EmployeeLeaveStore(),
  employeeReimburseDeductionStore: new EmployeeReimburseDeductionStore(),
  dashboardStore: new DashboardStore(),
  payrunStore: new PayRunStore(),

  loadingStore: new LoadingStore(),

  //admin store
  adminMenuStore: new AdminMemuStore(),
  adminPayNowStore: new AdminPayNowStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
