import { Menu } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import GetLogo from "../Media/logo/GetLogo";
import { useStore } from "../mobxStores/store";

export const AdminLeftMenu = {
  Dashboard: "Dashboard",
  AllClient: "All_Clients",
  History: "History",
  AdminList: "Admin_List",
  Setting: "Setting",
  EmployeeList: "Employee_List",
  Cronjob: "Cronjob",
  Coupon: "Coupon",
};

const LeftNavBarAdmin = () => {
  const { adminMenuStore } = useStore();
  const history = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          width: 144,
          height: 132,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
            height: "-webkit-fill-available",
            alignItems: "center",
          }}
        >
          {GetLogo("53", "66", true, undefined)}
        </div>
      </div>
      <Menu
        defaultSelectedKeys={[adminMenuStore.currentMemu]}
        mode="inline"
        items={[
          {
            label: AdminLeftMenu.Dashboard,
            key: AdminLeftMenu.Dashboard,
          },
          {
            label: AdminLeftMenu.AllClient,
            key: AdminLeftMenu.AllClient,
          },
          {
            label: AdminLeftMenu.History,
            key: AdminLeftMenu.History,
          },
          {
            label: AdminLeftMenu.AdminList,
            key: AdminLeftMenu.AdminList,
          },
          {
            label: AdminLeftMenu.Setting,
            key: AdminLeftMenu.Setting,
          },
          {
            label: AdminLeftMenu.EmployeeList,
            key: AdminLeftMenu.EmployeeList,
          },
          {
            label: AdminLeftMenu.Cronjob,
            key: AdminLeftMenu.Cronjob,
          },
          {
            label: AdminLeftMenu.Coupon,
            key: AdminLeftMenu.Coupon,
          },
        ]}
        onClick={(e) => {
          adminMenuStore.setCurrentMenu(e.key);
          history(`/admin/${e.key}`);
        }}
      />
    </div>
  );
};

export default observer(LeftNavBarAdmin);
