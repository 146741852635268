import { makeAutoObservable } from "mobx";

export default class AdminMemuStore {
  currentMemu: string = "Dashboard";

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentMenu = (temp: string | undefined) => {
    if (temp !== undefined) {
      this.currentMemu = temp;
    }
  };
}
