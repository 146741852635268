import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { DashboardMenuEnum } from "../afterLogin/Dashboard/DashboardMenuEnum";
import { IIrdUserDTO } from "../interface/UserInterface";
import { store } from "./store";

export default class IrdUserStore {
  currentIrdUser: IIrdUserDTO | undefined;

  numberOfUpcomingLeave: number = 0;

  numberOfUpcomingReimbursement: number = 0;

  numberOfUpcomingDeduction: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getCurrentIrdUserFromDB = async (fromWhere?: string) => {
    // this.currentIrdUser = temp;temp: IIrdUserDTO

    store.loadingStore.setIsLoading(true);

    console.log(fromWhere);

    let requestHeader = store.authStore.getRequestHeader();

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
    };

    console.log(bodyParameters);

    console.log(requestHeader);

    await axios
      .post("/Dashboard/Dashboard_GetData", bodyParameters, requestHeader)
      .then((response) => {
        let tempIrdUser: IIrdUserDTO = response.data;

        console.log(tempIrdUser);

        let tempTotalUpcomingLeave = 0;
        let tempTotalUpcomingReimbursement = 0;
        let tempTotalUpcomingDeduction = 0;

        tempIrdUser.manyIRDUserEmployeeDTO.forEach((employee) => {
          if (employee.manyIRDUserEmployeeUpcomingLeaveDTO !== undefined) {
            if (employee.manyIRDUserEmployeeUpcomingLeaveDTO.length > 0) {
              tempTotalUpcomingLeave++;
            }
          }

          if (
            employee.manyUpcomingReimbursementOrDeductionDTO !== undefined &&
            employee.manyUpcomingReimbursementOrDeductionDTO.length > 0
          ) {
            employee.manyUpcomingReimbursementOrDeductionDTO.forEach((item) => {
              if (item.isExtraPay === true) {
                tempTotalUpcomingReimbursement++;
              }

              if (item.isDeduction === true) {
                tempTotalUpcomingDeduction++;
              }
            });
          }
        });

        this.setNumberOfUpcomingLeave(tempTotalUpcomingLeave);

        this.setNumberOfUpcomingReimbursement(tempTotalUpcomingReimbursement);
        this.setNumberOfUpcomingDeduction(tempTotalUpcomingDeduction);

        this.setCurrentIrdUser(tempIrdUser);

        console.log(tempIrdUser);

        if (tempIrdUser.isShowUpcomingLeaveAlert === true) {
          store.dashboardStore.removeDashboardAlert();
        }

        store.loadingStore.setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          console.log(error);
          store.authStore.openAuthLogout();
          console.log("irdUserStore - 86");
          store.loadingStore.setIsLoading(false);
        }
      });
  };

  setCurrentIrdUser = (temp: IIrdUserDTO) => {
    this.currentIrdUser = toJS(temp);
    console.log(this.currentIrdUser);
  };

  setNumberOfUpcomingLeave = (temp: number) => {
    this.numberOfUpcomingLeave = temp;
  };
  setNumberOfUpcomingReimbursement = (temp: number) => {
    this.numberOfUpcomingReimbursement = temp;
  };
  setNumberOfUpcomingDeduction = (temp: number) => {
    this.numberOfUpcomingDeduction = temp;
  };

  removePayrun = async (removeId: string) => {
    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      RemovePayrunId: removeId,
    };

    let requestHeader = store.authStore.getRequestHeader();

    await axios
      .post("/Payrun/DeleteCurrentPayrun", bodyParameters, requestHeader)
      .then((response) => {
        if (this.currentIrdUser !== undefined) {
          this.currentIrdUser.manyPayrunDTO =
            this.currentIrdUser?.manyPayrunDTO.filter((x) => x.id !== removeId);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          console.log(error);
          store.authStore.openAuthLogout();
          console.log("irdUserStore - 135");
        }
      });
  };
}
