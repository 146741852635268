import { Key } from "antd/lib/table/interface";
import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import moment from "moment";
import shortid from "shortid";
import { AdminDashboardListEnum } from "../../adminLogin/Dashboard/AdminDashboardListEnum";
import { IPayrunEmployeeToPayDetailDTO } from "../../interface/UserInterface";
import { store } from "../store";

export interface IBankBulkUploadFormat {
  uploadName: string;
  date?: string;
  deductionFromWhichAccount: string;
  deductionAmount?: number;
  receiverParticular?: string;
  receiverCode?: string;
  receiverReference?: string;
  destinationAccount?: string;
  senderParticular: string;
  senderCode: string;
  senderReference: string;
  receiverName?: string;
}

export default class AdminPayNowStore {
  currentMemu: string = "Dashboard";

  payNowSelectedRowKeys: Key[] = [];

  csvData: IBankBulkUploadFormat[] = [];

  isShowPayNowConfirmation: boolean = false;

  reRender: string = shortid();

  amount: number | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setCSVData = (temp: IBankBulkUploadFormat[]) => {
    this.csvData = temp;
  };

  setAmount = (temp: number | undefined) => {
    this.amount = temp;
  };

  setReRender = () => {
    this.reRender = shortid();
    this.payNowSelectedRowKeys = [];
    this.csvData = [];
    this.amount = undefined;
  };

  setIsShowPayNowConfirmation = (result: boolean) => {
    this.isShowPayNowConfirmation = result;
  };

  setPayNowSelectedRowKeys = (temp: Key[]) => {
    this.payNowSelectedRowKeys = toJS(temp);
  };

  paymentProposingHandler = (position2: string | undefined) => {
    if (position2 !== undefined) {
      store.loadingStore.setIsLoading(true);
      const bodyParameters = {
        EmployeeToPayDetailIdArray: this.payNowSelectedRowKeys,
        IsFromNormalPayEmployee:
          position2 === AdminDashboardListEnum.PayEmployee,
        IsFromBankAccError: position2 === AdminDashboardListEnum.eeBankAccError,
        IsPayPAYEToHolding:
          position2 === AdminDashboardListEnum.PayPAYEtoHolding,
        IsPayServiceFee: position2 === AdminDashboardListEnum.PayServiceFee,
        IsPayIRDFromHolding:
          position2 === AdminDashboardListEnum.PayIRDfromHolding_DueNow ||
          position2 ===
            AdminDashboardListEnum.PayIRDfromHolding_Due20thThisMonth ||
          position2 === AdminDashboardListEnum.PayIRDfromHolding_Total
            ? true
            : false,
        IsRefundList: position2 === AdminDashboardListEnum.RefundList,
      };
      let requestHeader = store.authStore.getRequestHeader();

      axios
        .post("/Admin/PaymentProposing", bodyParameters, requestHeader)
        .then((response) => {
          store.loadingStore.setIsLoading(false);

          console.log(response.data);

          let result: IPayrunEmployeeToPayDetailDTO[] = response.data.item1;
          let deductionFromWhichAccount: string = response.data.item2;
          let resultMessage: string = response.data.item3;

          if (resultMessage === "ok") {
            // no need to set error
          } else {
            store.loadingStore.setError(resultMessage);
          }

          console.log(result);

          // download as cvs
          let csvDataTemp: IBankBulkUploadFormat[] = [];

          result.forEach((element) => {
            let temp: IBankBulkUploadFormat = {
              uploadName: "Empty",
              date: moment(element.payOnDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              ),
              deductionFromWhichAccount: deductionFromWhichAccount,
              deductionAmount: element.amount,
              receiverParticular: element.receiverParticular,
              receiverCode: element.receiverCode,
              receiverReference: element.receiverReference,
              destinationAccount: element.destinationBankAccNumber,
              senderParticular: "",
              senderCode: "",
              senderReference: "",
              receiverName: element.destinationBankAccName,
            };

            console.log(position2);

            if (
              position2 === AdminDashboardListEnum.PayIRDfromHolding_DueNow ||
              position2 ===
                AdminDashboardListEnum.PayIRDfromHolding_Due20thThisMonth ||
              position2 === AdminDashboardListEnum.PayIRDfromHolding_Total
            ) {
              if (
                element.batchNumberPayIRD !== undefined &&
                element.batchNumberPayIRD !== null
              ) {
                temp.uploadName = element.batchNumberPayIRD;
              }
            } else {
              if (
                element.batchNumber !== undefined &&
                element.batchNumber !== null
              ) {
                temp.uploadName = element.batchNumber;
              }
            }

            csvDataTemp.push(temp);
          });

          console.log(csvDataTemp);

          this.setCSVData(csvDataTemp);

          //show confirmation
          this.setIsShowPayNowConfirmation(true);
          store.loadingStore.setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            //not admin, then show login
            console.log(error);
            store.loadingStore.setIsLoading(false);
          }
        });
    }
  };

  bankUploadAmountConfirmation = (
    isConfirm: boolean,
    isCancel: boolean,
    amount: number,
    position2: string | undefined
  ) => {
    if (position2 !== undefined) {
      store.loadingStore.setIsLoading(true);

      const bodyParameters = {
        EmployeeToPayDetailIdArray: this.payNowSelectedRowKeys,
        IsConfirm: isConfirm,
        TotalAmountConfirmed: amount,
        IsCancel: isCancel,
        IsFromNormalPayEmployee:
          position2 === AdminDashboardListEnum.PayEmployee,
        IsFromBankAccError: position2 === AdminDashboardListEnum.eeBankAccError,
        IsPayPAYEToHolding:
          position2 === AdminDashboardListEnum.PayPAYEtoHolding,
        IsPayServiceFee: position2 === AdminDashboardListEnum.PayServiceFee,
        IsPayIRDFromHolding:
          position2 === AdminDashboardListEnum.PayIRDfromHolding_DueNow ||
          position2 ===
            AdminDashboardListEnum.PayIRDfromHolding_Due20thThisMonth ||
          position2 === AdminDashboardListEnum.PayIRDfromHolding_Total
            ? true
            : false,
        IsRefundList: position2 === AdminDashboardListEnum.RefundList,
      };
      let requestHeader = store.authStore.getRequestHeader();

      axios
        .post("/Admin/PaymentConfirmorCancel", bodyParameters, requestHeader)
        .then((response) => {
          if (response.data === "ok") {
            this.setIsShowPayNowConfirmation(false);

            this.setReRender();
          } else {
            store.loadingStore.setError(response.data);
          }
          store.loadingStore.setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            //not admin, then show login
            console.log(error);
            store.loadingStore.setIsLoading(false);
          }
        });
    }
  };
}
