export const ViewEmployeeEnum = {
    BasicInfo: "Basic information",
    Employment: "Employment",
    TaxKiwisaver: "Tax & Kiwisaver",
    Leave: "Leave",
    ExtraPayDeduction: "Extra pay & Deduction",
    Payslips: "Payslips"
};

export const ViewEmployee_LeaveTypeEnum = {
    AnnualLeaveAccrual: "Annual leave accrued",
    AnnualLeaveAvailable: "Annual leave available",
    SickLeave: "Sick leave",
    AlternativeLeave: "Alternative leave",
};