import { makeAutoObservable } from "mobx";

export default class LandingPageStore {
  isShowPrivacy: boolean = false;

  isShowTermsCondition: boolean = false;

  isContactUs: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsShowPrivacy = (temp: boolean) => {
    this.isShowPrivacy = temp;
  };

  setIsShowTermsCondition = (temp: boolean) => {
    this.isShowTermsCondition = temp;
  };

  setIsContactUs = (temp: boolean) => {
    this.isContactUs = temp;
  };

  getSoftwareName = (): string => {
    let softwareName: string = "";
    if (process.env.REACT_APP_BASE_SoftwareName !== undefined) {
      softwareName = process.env.REACT_APP_BASE_SoftwareName;
    }

    return softwareName;
  };
}
