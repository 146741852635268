import axios from "axios";
import { makeAutoObservable } from "mobx";
import {
  IIRDUserEmployeeDTO,
  IPayrunEmployeeToPayDTO,
} from "../interface/UserInterface";
import { store } from "./store";

export default class EmployeeStore {
  currentEmployee: IIRDUserEmployeeDTO | undefined;

  currentSelectedPayslipList: IPayrunEmployeeToPayDTO[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentEmployee = (result: IIRDUserEmployeeDTO | undefined) => {
    this.currentEmployee = result;

    if (result !== undefined) {
      localStorage.setItem("currentViewEmployee", result.id.toString());
    }
  };

  getCurrentEmployeeId = (): string | null => {
    let temp = localStorage.getItem("currentViewEmployee");

    return temp;
  };

  getCurrentEmployee = async (goBackFunction?: Function): Promise<void> => {
    store.loadingStore.setIsLoading(true);

    let tempEmployeeId = this.getCurrentEmployeeId();
    console.log("currentEmployeeId is " + tempEmployeeId);
    if (tempEmployeeId === null) {
      // push back to employee
      if (goBackFunction !== undefined) {
        goBackFunction();
      }
    }

    // try to get employee from DB

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      EmployeeId: tempEmployeeId,
    };
    axios
      .post(
        "/employee/ViewEmployee",
        bodyParameters,
        store.authStore.getRequestHeader()
      )
      .then((response) => {
        let temp: IIRDUserEmployeeDTO = response.data;

        console.log(response.data);
        this.setCurrentEmployee(temp);

        store.loadingStore.setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          if (goBackFunction !== undefined) {
            goBackFunction();
          }

          store.loadingStore.setIsLoading(false);
          console.log(error);
        }
      });
  };

  setCurrentSelectedPayslipList = (result: IPayrunEmployeeToPayDTO[]) => {
    this.currentSelectedPayslipList = [...result];
  };

  getEmployeePayslips = (employeeId: string, chedkDateTime?: string) => {
    store.loadingStore.setIsLoading(true);

    const bodyParameters = {
      AccessThisIrdUserId: store.authStore.getCurrentIrduserId(),
      EmployeeId: employeeId,
      CheckDateTime: chedkDateTime,
    };

    axios
      .post(
        "/employee/GetEmployeePayslips",
        bodyParameters,
        store.authStore.getRequestHeader()
      )
      .then((response) => {
        let temp: IPayrunEmployeeToPayDTO[] = response.data;

        console.log(temp);
        this.setCurrentSelectedPayslipList(temp);

        store.loadingStore.setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-lone-blocks
        {
          store.loadingStore.setIsLoading(false);

          console.log(error);
        }
      });
  };
}
